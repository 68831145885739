/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAddItemPopup } from '../../../hooks/useAddItemPopup'
import { TGalleryItemInfo } from '../../../store/gallery/types'
import { convertToDollars } from '../../../utils/convertToDollars'
import { buyItemAction } from '../../../utils/galleryUtils/itemsActions'
import { Spinner } from '../../../svg/Spinner'

interface PurchasePopupProps {
  item: TGalleryItemInfo
  tenderId: number
  purchasePopupActive: boolean
  setPurchasePopupActive: (value: boolean) => void
}

export const PurchasePopup: React.FC<PurchasePopupProps> = ({
  setPurchasePopupActive,
  tenderId,
  item,
  purchasePopupActive,
}) => {
  const dispatch = useDispatch()
  const { remove } = useAddItemPopup()
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (purchasePopupActive) {
        if (event.keyCode === 27) {
          document.getElementById('close-button')?.click()
        }

        if (event.keyCode === 13) {
          document.getElementById('buy-button')?.focus()
          document.getElementById('buy-button')?.click()
        }
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  const onPurchaseClick = () => {
    setLoading(true)
    buyItemAction(
      () => {
        setPurchasePopupActive(false)
        remove()
        setLoading(false)
      },
      () => {
        setShowError(true)
        setLoading(false)
      },
      tenderId,
      item.item_id,
      dispatch
    )
  }

  return (
    <div
      className='buy-now-popup w-full h-screen fixed bg-bluegray-900 top-0 left-0 z-110 bg-opacity-90 justify-center pt-1/12 popup-body'
      id='popup-body'
    >
      <div className='max-h-96 lg:w-8/12 mx-auto mt-24 max-w-3xl'>
        <div className='h-auto bg-white xrounded shadow-sm'>
          <div className='flex flex-wrap justify-between items-center w-full p-4 font-circa tracking-wide text-xl bg-gray-200 bg-opacity-50'>
            <div className='uppercase font-semibold text-red-600 mr-4'>FINAL SALE!</div>

            <div className='item-title text-black'>{item.product.title}</div>
          </div>

          <div className='text-xl p-6 space-y-8'>
            <div className='leading-none '>
              By clicking
              <span className='uppercase font-semibold'> PURCHASE </span>
              you agree to buy this item for:
            </div>

            <div className='flex flex-col space-y-2 mobile:space-y-0 mobile:flex-row items-center justify-between'>
              <div className='flex divide-x-0 flex-col smallMobile:flex-row smallMobile:divide-x flex-wrap justify-center'>
                {item.category_id === 1 ? (
                  <>
                    {item.product.asking_price_per_carat ? (
                      <div className='price-per-carat text-center smallMobile:pr-4'>
                        {`${convertToDollars(item.product.asking_price_per_carat)} /ct`}
                      </div>
                    ) : null}
                    {item.product.rap_list_price_discount ? (
                      <div className='rap-list-price-discount text-center smallMobile:px-4'>
                        {`${item.product.rap_list_price_discount}% of RAP`}
                      </div>
                    ) : null}
                  </>
                ) : null}
                <div className='asking-price text-center smallMobile:pl-4'>
                  {`${convertToDollars(item.product.asking_price)} Total`}
                </div>
              </div>
              <div className='text-black text-base px-2'>
                <span className='text-red-600 uppercase font-semibold'>All sales are final</span>
              </div>
            </div>
          </div>

          <div className='p-6'>
            <div className='flex justify-between w-full'>
              <button
                type='button'
                id='close-button'
                onClick={() => setPurchasePopupActive(false)}
                className='border border-bluegray-100 bg-bluegray-50 text-bluegray-700 rounded-md px-4 py-2 select-none hover:bg-bluegray-100 focus:outline-none '
              >
                <span className='text'>Cancel</span>
              </button>

              <div className='relative'>
                {loading ? (
                  <div className='absolute inset-0 flex justify-center items-center z-10'>
                    <Spinner classname='!text-green-900' />
                  </div>
                ) : null}

                <button
                  type='button'
                  id='buy-button'
                  className='rounded-md select-none h-full focus:outline-none blank text-base uppercase px-10 font-semibold bg-green-50 border-green-200 border hover:bg-green-100 text-green-900'
                  onClick={onPurchaseClick}
                  disabled={loading}
                >
                  <span className='text'>PURCHASE</span>
                </button>
              </div>
            </div>

            {showError ? (
              <div className='text-red-500 w-full mt-2 px-2 text-lg'>
                You do not have access to bid on CIRCA tenders. Please contact{' '}
                <a href='mailto:tender@circajewels.com' className=' underline hover:text-red-700'>
                  tender@circajewels.com
                </a>{' '}
                to become a verified user.
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
